import React, { FunctionComponent } from "react";
import { Voyager } from "graphql-voyager";
import ReactGA from "react-ga";
import { introspectionProvider } from "./utils";

interface IProps {}

interface Props extends IProps {}

ReactGA.initialize("UA-82148343-10");
ReactGA.pageview(window.location.pathname + window.location.search);

const App: FunctionComponent<IProps> = function (_props: Props) {
  return (
    <div className="App">
      <Voyager
        introspection={introspectionProvider}
        displayOptions={{ hideRoot: false }}
        hideDocs={false}
        hideSettings={false}
        //@ts-ignore
        workerURI={process.env.PUBLIC_URL + "/voyager.worker.js"}
      />
    </div>
  );
};

export default App;
