import axios from "axios";

export async function introspectionProvider(): Promise<any> {
  const data = JSON.stringify({
      operationName: "IntrospectionQuery",
      query:
        "query IntrospectionQuery {\n  __schema {\n    queryType {\n      name\n    }\n    mutationType {\n      name\n    }\n    subscriptionType {\n      name\n    }\n    types {\n      ...FullType\n    }\n    directives {\n      name\n      description\n      locations\n      args {\n        ...InputValue\n      }\n    }\n  }\n}\n\nfragment FullType on __Type {\n  kind\n  name\n  description\n  fields(includeDeprecated: true) {\n    name\n    description\n    args {\n      ...InputValue\n    }\n    type {\n      ...TypeRef\n    }\n    isDeprecated\n    deprecationReason\n  }\n  inputFields {\n    ...InputValue\n  }\n  interfaces {\n    ...TypeRef\n  }\n  enumValues(includeDeprecated: true) {\n    name\n    description\n    isDeprecated\n    deprecationReason\n  }\n  possibleTypes {\n    ...TypeRef\n  }\n}\n\nfragment InputValue on __InputValue {\n  name\n  description\n  type {\n    ...TypeRef\n  }\n  defaultValue\n}\n\nfragment TypeRef on __Type {\n  kind\n  name\n  ofType {\n    kind\n    name\n    ofType {\n      kind\n      name\n      ofType {\n        kind\n        name\n        ofType {\n          kind\n          name\n          ofType {\n            kind\n            name\n            ofType {\n              kind\n              name\n              ofType {\n                kind\n                name\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    }),
    config = {
      method: "post",
      url: process.env.REACT_APP_API_ENDPOINT!,
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        "x-app-id": process.env.REACT_APP_API_APP_ID!,
        "x-client-id": process.env.REACT_APP_API_CLIENT_ID!,
      },
      data,
    };

  return axios
    .request(config)
    .then(({ data }: { data: any }) => {
      return data;
    })
    .catch((error: any) => {
      console.log(error);
    });
}
